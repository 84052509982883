import { trackButtonClick } from 'util/snowplow'
/**
 * @ngdoc directive
 * @name snowplow
 * @module map3.core
 * @description
 * Ignore subsequent clicks to a button/input/a element.
 *
 * Use this to protect form submits from accidental double-clicks.
 *
 *
 * ```html
 * <input type="submit" single-click="temporary" />
 * ```
 *
 * Will attach `disabled="disabled"` attribute and `class="disabled"` class.
 * If `temporary` is set, the disable will be removed after 5 seconds
 *
 */
export default /* @ngInject */ function singleClickDirective() {
    var directive = {
        link: singleClickLinkFn,
    }

    return directive

    ////////////////////

    function singleClickLinkFn(scope, element, attrs) {
        element.on('click', trackWithSnowplow)

        scope.$on('$destroy', () => {
            element.off('click', trackWithSnowplow)
        })

        var section = attrs.snowplowTrack

        function trackWithSnowplow($evt) {
            var target = $evt.target
            var tgtNodeName = target.nodeName.toLowerCase()
            var role = target.getAttribute('role')

            if (tgtNodeName === 'a') {
                trackButtonClick(
                    {
                        componentType: role || 'link',
                        label: target.innerText,
                        value: [
                            {
                                metadata: {
                                    href: target.getAttribute('href'),
                                    section,
                                },
                            },
                        ],
                    },
                    'link'
                )
            }
        }
    }
}
