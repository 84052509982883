import _ from 'lodash'

import { ErrorReplacementId } from 'constants.es6'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { RoutingAuthInstance } from 'auth/RoutingAuth.factory'
import { UpdateNotifierInstance } from 'services/UpdateNotifier'
import { UserInstance } from 'auth/User.factory'

interface INavigationCtrlInstance {
    UpdateNotifier: UpdateNotifierInstance
    showNav: boolean
    messages: any[]
    toggleCssTheme: any

    activate: () => void
    goToHome: () => void
    updateNavDisplay: () => void
    changeRole: () => void
    openMenu: ($mdOpenMenu: any, e: Event) => void
    readMessage: (message: any) => void
    deleteMessage: ($event: Event, message: any) => void
    logout: () => void
}

type TRootScopeServiceBindings = ng.IRootScopeService & Pick<INavigationCtrlInstance, 'goToHome'>

export default /* @ngInject */ function NavigationCtrl(
    this: unknown,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    $uibModal: ng.ui.bootstrap.IModalService,
    RoutingAuth: RoutingAuthInstance,
    User: UserInstance,
    Notification: NotificationServiceInstance,
    UpdateNotifier: UpdateNotifierInstance,
    InternalMessaging: any,
    ThemeManager: any
) {
    const vm = this as INavigationCtrlInstance

    vm.UpdateNotifier = UpdateNotifier
    vm.changeRole = changeRole
    vm.openMenu = openMenu
    vm.goToHome = goToHome
    ;($scope.$root as TRootScopeServiceBindings).goToHome = goToHome
    vm.logout = logout
    vm.showNav = false
    vm.messages = []
    vm.readMessage = readMessage
    vm.deleteMessage = deleteMessage

    vm.toggleCssTheme = ThemeManager.toggle

    activate()

    ///////////////

    function activate() {
        $scope.$on('user.update', updateNavDisplay)

        // the user is about to timeout
        $scope.$on('IdleWarn', (_, countdown: number) => {
            Notification.error(
                `Your MAP3 session will expire in ${countdown} seconds.`,
                false,
                ErrorReplacementId.IdleWarn
            )
        })

        // the user has timed out
        $scope.$on('IdleTimeout', () => {
            vm.logout()
            Notification.hide()
        })

        // the user executed an action before timing out
        $scope.$on('IdleEnd', () => {
            Notification.hide()
        })
    }

    function goToHome() {
        const route = User.isAuthenticated()
            ? RoutingAuth.getDefaultState()
            : RoutingAuth.loginState()

        $state.go(route, {}, { reload: true })
    }

    /**
     * User navigation (eg, links to pages or the top-right dropdown menu)
     * must be hidden unless the current user is logged in and has,
     * or has selected, only one role
     */
    function updateNavDisplay() {
        if (User.isAuthenticated() && User.getRoles().length === 1) {
            vm.showNav = true
            InternalMessaging.checkForNewMessages().then((data: any) => {
                vm.messages = data
            })
        } else {
            vm.showNav = false
        }
    }

    function changeRole() {
        User.setActiveRoles(false)
        vm.messages = []
        $state.go(RoutingAuth.getOption('defaultStateForMultiRole'))
    }

    function openMenu($mdOpenMenu: any, e: Event) {
        $mdOpenMenu(e)
    }

    function readMessage(message: any) {
        InternalMessaging.readMessage(message)
        _.remove(vm.messages, message)
    }

    function deleteMessage($event: Event, message: any) {
        $event.stopPropagation()

        InternalMessaging.deleteMessage(_.get(message, 'id'))
        _.remove(vm.messages, message)
    }

    function logout() {
        User.logout().then(() => {
            vm.messages = []
            $state.go('auth.login')
        })
    }
}
