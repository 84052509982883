import _ from 'lodash'
import fp from 'lodash/fp'
import { AdminModuleInstance } from './AdminModule.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { AdminUsersModuleInstance } from './AdminUsersModule.factory'
interface IAdminManageUserCtrlInstance {
    users: TUser[]
    groups: UserGroup[]
    roles: TRole[]
    selectedGroups: string[]
    usersPredicate: any
    perfieldPredicate: any
    userSearchQuery: string

    showCreateGroupModal: () => void
    suspendUser: (user: TUser) => void
    activateUser: (user: TUser) => void
    deleteUser: (username: string) => void
    filterUsers: (user: TUser) => boolean
    userSearchFilter: (item: any) => boolean
}
export default /* @ngInject */ function AdminManageUsersCtrl(
    this: unknown,
    $state: ng.ui.IStateService,
    $uibModal: ng.ui.bootstrap.IModalService,

    AdminUsersModule: AdminUsersModuleInstance,
    AdminModule: AdminModuleInstance,
    Notification: NotificationServiceInstance,
    MapDialog: any,
    UserPreferences: any,
    users: TUser[],
    GroupModel: any
) {
    const adminVm = this as IAdminManageUserCtrlInstance

    adminVm.users = users
    adminVm.groups = fp.flow(
        fp.map((user: TUser) => user.groups),
        fp.flatten,
        fp.uniqBy('uri')
    )(users)
    adminVm.roles = fp.flow(
        fp.map((user: TUser) => user.roles),
        fp.flatten,
        fp.uniq
    )(users)

    adminVm.selectedGroups = []

    adminVm.usersPredicate = UserPreferences.get($state.current.name, 'adminVm.usersPredicate', [])
    adminVm.perfieldPredicate = UserPreferences.get(
        $state.current.name,
        'adminVm.perfieldPredicate',
        {}
    )

    adminVm.showCreateGroupModal = showCreateGroupModal

    adminVm.suspendUser = suspendUser
    adminVm.activateUser = activateUser
    adminVm.deleteUser = deleteUser

    adminVm.filterUsers = filterUsers
    adminVm.userSearchFilter = userSearchFilter
    adminVm.userSearchQuery = ''
    //////////////////////////////

    const confirmDialog = MapDialog.confirm()
        .title('Are you sure?')
        .textContent('Are you sure you want to suspend this user?')
        .ok('Yes')
        .cancel('No')

    function suspendUser(user: TUser) {
        confirmDialog.textContent('Are you sure you want to suspend this user?')

        MapDialog.show(confirmDialog)
            .then(function () {
                const suspendPromise = AdminUsersModule.suspendUser(user.uri)

                Notification.forPromise(
                    suspendPromise,
                    'User suspended successfully',
                    'Something went wrong'
                )

                return suspendPromise
            })
            .then(function () {
                user.status = 'Suspended'
            })
    }

    function activateUser(user: TUser) {
        confirmDialog.textContent('Are you sure you want to unsuspend this user?')

        MapDialog.show(confirmDialog)
            .then(() => {
                const unsuspendPromise = AdminUsersModule.activateUser(user.uri)

                Notification.forPromise(
                    unsuspendPromise,
                    'User activated successfully',
                    'Something went wrong'
                )

                return unsuspendPromise
            })
            .then(() => {
                user.status = 'Active'
            })
    }

    function deleteUser(username: string) {
        confirmDialog.textContent('Are you sure you want to delete this user?')

        MapDialog.show(confirmDialog)
            .then(() => {
                const deletePromise = AdminUsersModule.deleteUser(username)

                Notification.forPromise(
                    deletePromise,
                    'User deleted successfully',
                    'Something went wrong'
                )

                return deletePromise
            })
            .then(() => {
                _.remove(adminVm.users, { username: username })
            })
    }

    function filterUsers(user: TUser) {
        let result = true

        if (adminVm.selectedGroups.length) {
            result =
                result &&
                user.groups.some((group) => {
                    return _.includes(adminVm.selectedGroups, _.get(group, 'label'))
                })
        }

        return result
    }

    function userSearchFilter(item: TUser) {
        let values = _.values(
            _.pick(item, ['firstname', 'lastname', 'email', 'username'])
        ) as string[]
        values = values.concat(_.map(item.groups, 'label'))
        return _.some(values, (value) => _.includes(value, adminVm.userSearchQuery))
    }

    interface IShowCreateGroupModal {
        groups: UserGroup[]

        transformValueToGroup: (name: string) => UserGroup
        addGroup: (group: UserGroup) => void
        removeGroup: (group: UserGroup) => void
        closeModal: () => void
    }

    function showCreateGroupModal() {
        const instance = $uibModal.open({
            templateUrl: 'js/admin/createGroupModal.tpl.html',
            controller: function (this: unknown) {
                const createGroupVm = this as IShowCreateGroupModal

                createGroupVm.groups = adminVm.groups

                createGroupVm.transformValueToGroup = transformValueToGroup

                createGroupVm.addGroup = addGroup

                createGroupVm.removeGroup = removeGroup

                createGroupVm.closeModal = instance.close

                function addGroup(group: UserGroup) {
                    const addPromise = AdminModule.addGroup(group)

                    addPromise
                        .then((res) => {
                            group.uri = res.data
                        })
                        .catch(() => {
                            _.remove(createGroupVm.groups, group)
                        })

                    Notification.forPromise(
                        addPromise,
                        'Group created successfuly!',
                        'Something went wrong'
                    )
                }

                function removeGroup(group: UserGroup) {
                    createGroupVm.groups = _.filter(
                        adminVm.groups,
                        (adminVmGroup) => adminVmGroup !== group
                    )
                    const moveTaskToGroup = _.find(createGroupVm.groups, GroupModel.isPublic)
                    const removePromise = AdminModule.removeGroup(
                        group,
                        moveTaskToGroup as UserGroup
                    )

                    removePromise.catch(function (res) {
                        createGroupVm.groups.push(group)
                    })

                    Notification.forPromise(removePromise, 'Group successfuly removed!')
                }

                function transformValueToGroup(name: string) {
                    return {
                        label: name,
                        uri: '',
                    }
                }
            },
            controllerAs: 'createGroupVm',
        })
    }
}

AdminManageUsersCtrl.resolve = {
    users: /* @ngInject */ function (AdminUsersModule: AdminUsersModuleInstance) {
        return AdminUsersModule.getUsers()
    },
}
