import map3core from 'app.core'
import map3graph from './graph'

import atCompleteDirective from './atComplete.directive'
import autoLowercaseDirective from './autoLowercase.directive'
import ctrlEnterSubmitDirective from './form/ctrlEnterSubmit.directive'
import isEditableDirective from './form/isEditable.directive'
import jsonTextareaDirective from './jsonTextarea.directive'
import mapSystemButtonDirective from './mapSystemButton.directive'
import onReloadDirective from './onReload.directive'
import passwordVisibleDirective from './passwordVisible.directive'
import renderMetadataDirective from './renderMetadata.directive'
import { resizableDirective, resizeColumnsDirective } from './resizable.directive'
import scrollableContainerDirective from './scrollableContainer.directive'
import scrollToDirective from './scrollTo.directive'
import singleClickDirective from './singleClick.directive'
import snowplowTrackDirective from './snowplowTrack.directive'
import clickAndWaitDirective from './clickAndWait.directive'
import stickyHeaderDirective from './stickyHeader.directive'
import tableOrderDirective, { sortIndicatorComponent } from './tableOrder.directive'
import isUnsupportedDirective from './tasksIsUnsupported.directive'
import timezoneInputDirective from './timezoneInput.directive'
import tooltipDirective from './tooltip.directive'
import tooltipTableDetailsDirective from './tooltipTableDetails.directive'
import { tzDateDirective, tzDateFilter } from './tzDate.directive'
import renderIssueTypeAndStatusComponent from './renderIssueTypeAndStatus.component'
import qrCodeComponent from './qrCode.component'
import songTitleFilter from './songTitle.filter'
import {
    uniqueEmailValidatorDirective,
    uniqueUsernameValidatorDirective,
} from './form/uniqueValidators.directive'
import videoSpeedDirective from './video-speed/videoSpeed.directive'
import timeFormatComponent from './time-format/timeFormat.component'
import answerQuestionsDirective from './answer-questions/answerQuestions.directive'
import answerQuestionsWithSuggestionsDirective from './answer-questions/answerQuestionsWithSuggestions.directive'
import questionInputDirective from './answer-questions/questionInput.directive'
import answerQuestionsSideBySideDirective from './answer-questions/answerQuestionsSideBySide.directive'
import recordedAnswersDirective from './recorded-answers/recordedAnswers.directive'
import recordedAnswerForSingleQuestionDirective from './recorded-answers/recordedAnswerForSingleQuestion.directive'
import highlightErrorsDirective from './form/highlightErrors.directive'
import simpleTagsDirective from './form/simpleTags.directive'
import bootstrapDatepickerDirective from './form/bootstrapDatepicker.directive.js'
import songTagsComponent from './form/songTags.component'
import tablePaginationComponent from './tablePagination.component'
import compareToDirective from './compareTo.directive'
import tagValueDirective from './tagValue.directive'
import spinnerOnLoadDirective from './spinnerOnLoad.directive'
import syntheticScrollDirective from './syntheticScroll.directive'

import videoDescriptionComponent from './videoDescription.component'
import annotationSortingComponent from './annotation.sorting.component'
import focusAnnotationDirective from './focusAnnotation.directive'
import glossaryImageComponent from './glossaryImage.component'

import asyncGuardDirective from './asyncGuard.directive'

import {
    collapsableComponent,
    collapsableToggleDirective,
    collapsableToggleAllChildrenDirective,
} from './collapsable.component'

const map3directives = angular
    .module('map3.directives', [map3core, map3graph])

    .directive('videoSpeed', videoSpeedDirective)
    .directive('atComplete', atCompleteDirective)
    .directive('autoLowercase', autoLowercaseDirective)
    .directive('ctrlEnterSubmit', ctrlEnterSubmitDirective)
    .directive('isEditable', isEditableDirective)
    .directive('jsonTextarea', jsonTextareaDirective)
    .directive('mapSystemButton', mapSystemButtonDirective)
    .directive('onReload', onReloadDirective)
    .directive('passwordVisible', passwordVisibleDirective)
    .directive('renderMetadata', renderMetadataDirective)
    .directive('resizable', resizableDirective)
    .directive('resizeColumns', resizeColumnsDirective)
    .directive('scrollableContainer', scrollableContainerDirective)
    .directive('scrollTo', scrollToDirective)
    .directive('singleClick', singleClickDirective)
    .directive('snowplowTrack', snowplowTrackDirective)
    .directive('clickAndWait', clickAndWaitDirective)
    .directive('stickyHeader', stickyHeaderDirective)
    .directive('tableOrder', tableOrderDirective)
    .component('sortIndicator', sortIndicatorComponent)
    .directive('isUnsupported', isUnsupportedDirective)
    .directive('timezoneInput', timezoneInputDirective)
    .directive('tooltip', tooltipDirective)
    .directive('tooltipTableDetails', tooltipTableDetailsDirective)
    .directive('tzDate', tzDateDirective)
    .filter('tzDate', tzDateFilter)
    .filter('songTitle', songTitleFilter)
    .directive('uniqueEmailValidator', uniqueEmailValidatorDirective)
    .directive('uniqueUsernameValidator', uniqueUsernameValidatorDirective)
    .directive('answerQuestions', answerQuestionsDirective)
    .directive('questionInput', questionInputDirective)
    .directive('answerQuestionsSideBySide', answerQuestionsSideBySideDirective)
    .directive('answerQuestionsWithSuggestions', answerQuestionsWithSuggestionsDirective)
    .directive('recordedAnswers', recordedAnswersDirective)
    .directive('recordedAnswerSingleQuestion', recordedAnswerForSingleQuestionDirective)
    .directive('highlightErrors', highlightErrorsDirective)
    .directive('simpleTags', simpleTagsDirective)
    .directive('bootstrapDatepicker', bootstrapDatepickerDirective)
    .directive('compareTo', compareToDirective)
    .directive('tagValue', tagValueDirective)
    .directive('spinnerOnLoad', spinnerOnLoadDirective)
    .component('renderIssueTypeAndStatus', renderIssueTypeAndStatusComponent)
    .component('qrCode', qrCodeComponent)
    .component('glossaryImage', glossaryImageComponent)

    .constant('FRAME_EPSILON', 0.000001)

    .component('videoDescription', videoDescriptionComponent)
    .component('collapsable', collapsableComponent)
    .directive('collapsableToggle', collapsableToggleDirective)
    .directive('collapsableToggleAll', collapsableToggleAllChildrenDirective)
    .component('annotationSorting', annotationSortingComponent)
    .directive('focusAnnotation', focusAnnotationDirective)
    .component('tablePagination', tablePaginationComponent)
    .component('songTags', songTagsComponent)
    .directive('asyncGuard', asyncGuardDirective)
    .component('timeFormat', timeFormatComponent)
    .directive('syntheticScroll', syntheticScrollDirective)

export default map3directives.name
