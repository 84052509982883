import _ from 'lodash'
import MapCachedImmutableStateToThis from 'services/MapCachedImmutableStateToThis'

/**
 * @ngdoc component
 * @name selectionInfo
 * @module map3.scenes
 *
 * @description
 * Render information about a single scene's start/end
 *
 * @param {string} type The type of the selection - acts, scenes, sub scenes
 * @param {string} mode The selection mode - single or multi
 * @param {string=} filmstripType The filmstrip render type
 */
const selectionInfoComponent = {
    controller: selectionInfoController,
    bindings: {
        type: '@',
        mode: '@?',
        filmstripType: '=?',
        renderMode: '=?',
    },
    template: `
    <div class="d-flex flex-column flex-wrap align-content-around h-100">
        <h6 class="d-flex justify-content-between align-items-center">
            {{ ::$ctrl.typeLabel }}
            <div>
                <small class="text-muted"
                    ng-show="$ctrl.renderMode !== 'hidden'"
                    ng-bind="$ctrl.multiSelectionLabel"
                ></small>
                <filmstrip-control-button
                    ng-show="$ctrl.renderMode !== 'hidden' && $ctrl.filmstripType"
                    filmstrip-type="$ctrl.filmstripType"
                ></filmstrip-control-button>

                <button type="button" class="btn btn-link-light p-0"
                    ng-show="!$ctrl.staticRenderMode"
                    ng-click="$ctrl.toggleRenderMode()"
                >
                    <i ng-if="$ctrl.renderMode === 'default' || $ctrl.renderMode === 'condensed'"
                        class="material-icons md-18 "
                    >
                            remove_circle_outline
                    </i>
                    <i ng-if="$ctrl.renderMode === 'hidden'"
                        class="material-icons md-18"
                    >
                        add_circle_outline
                    </i>
                </button>
            </div>
        </h6>

        <div class="d-flex flex-grow justify-content-between align-items-center time-container"

            ng-show="$ctrl.renderMode === 'default' && $ctrl.mode !== 'none'"
        >
            <div class="d-flex flex-column mr-3">
                <small class="text-uppercase tiny text-light">Start</small>
                <span
                    ng-bind="$ctrl.frameToTime($ctrl.selectionData.startFrame) | timestamp:true"
                ></span>
            </div>
            <div class="d-flex flex-column">
                <small class="text-uppercase tiny text-light">End</small>
                <span
                    ng-bind="$ctrl.frameToTime($ctrl.selectionData.endFrame) | timestamp:true"
                ></span>
            </div>
        </div>

        <div ng-hide="$ctrl.renderMode === 'hidden'" class="d-flex align-items-end mt-auto">
            <zoom-level class="d-flex m-0 w-100" step="{{::$ctrl.zoomStepValue}}"></zoom-level>
        </div>
    </div>
    `,
}

export default selectionInfoComponent

const MODE_NONE = 'none'
const MODE_SINGLE = 'single'
const MODE_MULTI = 'multi'
const MODE_DEFAULT = MODE_NONE
const MODES = [MODE_NONE, MODE_SINGLE, MODE_MULTI]

const RENDER_MODES = ['default', 'condensed', 'hidden']

/* @ngInject */
function selectionInfoController($scope, $element, $attrs, $ngRedux, SharedVideoAPI) {
    $element.addClass('selection-info')

    const $ctrl = this

    $ctrl.$onInit = $onInit
    $ctrl.$onChanges = $onChanges

    $ctrl.frameToTime = frameToTime
    $ctrl.toggleRenderMode = toggleRenderMode

    return $ctrl

    //////////////////////////

    function $onInit() {
        $ctrl.staticRenderMode = angular.isDefined($attrs.static)
        $ctrl.renderMode = $ctrl.renderMode || 'default'
        $ctrl.mode = $ctrl.mode || MODE_NONE

        if (_.includes(['markers'], $ctrl.type)) {
            $ctrl.zoomStepValue = 100
        }

        $scope.$watch('$ctrl.elements', updateSelectionData)

        SharedVideoAPI.onLoad(handleVideoApiReady)
    }

    function $onChanges(changes) {
        if (changes.type) {
            $ctrl.typeLabel = _.startCase(changes.type.currentValue)
        }

        if (changes.mode) {
            $ctrl.mode = _.includes(MODES, changes.mode.currentValue)
                ? changes.mode.currentValue
                : MODE_DEFAULT
        }
    }

    function handleVideoApiReady(videoApiParam) {
        $ctrl.videoApi = videoApiParam

        let stateKey
        if (_.includes(['acts', 'scenes', 'subScenes'], $ctrl.type)) {
            stateKey = `sceneData.present.${$ctrl.type}`
        } else if (_.includes(['highlights'], $ctrl.type)) {
            stateKey = `highlightsData.undoableHighlights.present.highlights`
        } else if (_.includes(['markers'], $ctrl.type)) {
            stateKey = `contentMarkersData.undoableContentMarkers.present.contentMarkers`
        }

        const mapStateToThis = MapCachedImmutableStateToThis({
            [stateKey]: 'elements',
        })

        const unsubscribe = $ngRedux.connect(mapStateToThis)($ctrl)
        $scope.$on('$destroy', unsubscribe)
        $scope.$on('$destroy', mapStateToThis.clear)
    }

    function updateSelectionData() {
        const selected = _.filter($ctrl.elements, (element) => {
            return element.$$isSelected
        })

        if ($ctrl.mode === MODE_MULTI) {
            if (selected.length > 1) {
                $ctrl.multiSelectionLabel = `(${selected.length} selected)`
            } else {
                $ctrl.multiSelectionLabel = ''
            }

            $ctrl.selectionData = {
                startFrame: _.get(selected, `[0].startFrame`, 0),
                endFrame: _.get(selected, `[${selected.length - 1}].endFrame`, 0),
            }
        } else {
            $ctrl.multiSelectionLabel = ''

            if (selected.length === 1) {
                $ctrl.selectionData = _.pick(selected[0], ['startFrame', 'endFrame'])
            } else {
                // do not show any data when multiple elements are selected
                // in "single" mode
                $ctrl.selectionData = null
            }
        }
    }

    function frameToTime(frameNumber) {
        if (!frameNumber) {
            return 0
        }
        if ($ctrl.videoApi) {
            return $ctrl.videoApi.convertFrameToSeconds(frameNumber) || 0
        } else {
            return 0
        }
    }

    function toggleRenderMode() {
        const currentIndex = RENDER_MODES.indexOf($ctrl.renderMode)
        const newIndex = currentIndex + 1 < RENDER_MODES.length ? currentIndex + 1 : 0
        $ctrl.renderMode = RENDER_MODES[newIndex]
    }
}
