export default /* @ngInject */ function dateFiledFilterDirective(TablePerfieldFilterReset) {
    var directive = {
        restrict: 'E',
        scope: {
            predicate: '=',
        },
        template: `
            <button
                class="btn btn-outline-secondary btn-sm"
                ng-show="showResetFilterButton"
                ng-click="resetFilters(predicate)"
            >
                Reset Filtering
            </button>
        `,
        link: dateFieldFilterLinkFn,
    }

    return directive

    //////////////////

    function dateFieldFilterLinkFn(scope, element, attr) {
        scope.resetFilters = TablePerfieldFilterReset.resetFilters

        scope.$watch(
            function () {
                return TablePerfieldFilterReset.isPerfieldPredicateDirty(scope.predicate)
            },
            (result) => {
                scope.showResetFilterButton = result
            }
        )
    }
}
