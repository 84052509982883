import _ from 'lodash'
import fp from 'lodash/fp'

export default /* @ngInject */ function AdminTasksListCtrl(
    PROGRAMMING_TYPE_CONSTANTS,
    $scope,
    $filter,
    AdminTasksListDataLoader,
    TasksExportModal,
    DeltaExportModal,
    QARouter,
    programmingTypeGroups,
    commonOptions
) {
    const vm = this
    vm.$onInit = $onInit
    vm.programmingTypeGroups = programmingTypeGroups
    vm.videos = _.flatMap(programmingTypeGroups, (t) => {
        // Because data structure of episodes is different we need to make this
        if (!t.videos && t.series) {
            // Episodes dose not contain videos, but series instead
            return t.series.map((s) => {
                // When we find them, we loop them and we attach programmingType, so we can build the list
                return {
                    ...s,
                    programmingType: PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE,
                }
            })
        }

        return t.videos
    })
    vm.commonOptions = commonOptions

    // DATA LOAD
    vm.loadAllTaskDetails = AdminTasksListDataLoader.loadAllTaskDetails
    vm.loadSeasonVideos = AdminTasksListDataLoader.loadSeasonVideos
    vm.loadSeasonTasks = AdminTasksListDataLoader.loadSeasonTasks
    vm.loadVideoTasks = AdminTasksListDataLoader.loadVideoTasks

    // UTIL
    vm.getTaskData = getTaskData
    vm.hitCanBeReviewed = hitCanBeReviewed
    vm.searchString = ''
    vm.urlForTask = QARouter.urlFor

    vm.showTaskExportsModal = TasksExportModal.showModal
    vm.showDeltaExportsModal = DeltaExportModal.showModal

    return vm

    ////////////////////////

    function $onInit() {
        $scope.$watch('vm.searchString', function () {
            const filteredVideos = $filter('filter')(vm.videos, vm.searchString)

            vm.programmingTypeGroups = _.map(
                PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPES,
                (programmingType) =>
                    AdminTasksListDataLoader.processProgrammingTypeData(
                        programmingType,
                        filteredVideos.filter((v) => v.programmingType === programmingType.type)
                    )
            )
        })
    }

    // UTIL
    function getTaskData(video, taskDetailsLabel) {
        return _.find(video.tasks, { task_details: taskDetailsLabel })
    }

    function hitCanBeReviewed(hit) {
        if (!hit.id || !hit.class) {
            return false
        }

        if (hit.status === 'finished') {
            return true
        } else {
            return (
                hit.workers_info &&
                hit.workers_info.length &&
                _.some(hit.workers_info, (worker) => worker.role === 'QA')
            )
        }
    }
}

AdminTasksListCtrl.resolve = {
    programmingTypeGroups: /* @ngInject */ (AdminTasksListDataLoader) => {
        return AdminTasksListDataLoader.loadProgrammingTypeGroups()
    },
    commonOptions: /* @ngInject */ (AdminTasksListModule) => {
        return AdminTasksListModule.getCommonOptions()
    },
}

export /* @ngInject */ function AdminTasksListDataLoaderFactory(
    PROGRAMMING_TYPE_CONSTANTS,
    NO_LOAD_OVERLAY,
    $http,
    $q
) {
    const AdminTasksListDataLoader = {
        /**
         * Load all programming type data from the backend and return programming type groups
         * @return {Promise} [
         *   {
         *       type: {string},         // the programming type
         *       fields: [{string}]      // the special fields for the programming type
         *       subTypes: [{string}],   // the programming sub types for the programming type
         *       length: {number}        // the number of items in the group
         *
         *       // for type "episode":
         *       series: [
         *           {
         *               seriesTitle: {string},  // series title
         *               seasons: [
         *                   {
         *                       seriesTitle: {string},
         *                       seasonNumber: {number}
         *
         *                       {Promise}.videos: [
         *                           {
         *                               seriesTitle: {string}
         *                               seasonNumber: {number}
         *                               episodeNumber: {number},
         *                               [...original video fields],
         *
         *                               {Promise}.tasks: [...task objects...]
         *                           }
         *                       ]
         *
         *                       {Promise}.allTaskDetails: [
         *                          // array of unique task details for all videos
         *                       ]
         *                   }
         *               ]
         *           }
         *       ]
         *
         *       // for all other types:
         *       videos: [
         *           {
         *               ...original video fields,
         *              {Promise}.tasks: [...task objects...]
         *           }
         *       ]
         * ]
         */
        loadProgrammingTypeGroups() {
            return $q.all(
                _.map(PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPES, loadDataForProgrammingType)
            )

            function loadDataForProgrammingType(programmingType) {
                return $http
                    .get('/api/admin/tasks/video-data', {
                        params: { type: programmingType.type },
                    })
                    .then((res) => res.data)
                    .then((data) =>
                        AdminTasksListDataLoader.processProgrammingTypeData(programmingType, data)
                    )
            }
        },

        processProgrammingTypeData(programmingType, data) {
            switch (programmingType.type) {
                case PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE:
                    return {
                        ...programmingType,
                        series: _.map(data, (series) => {
                            return {
                                ...series,
                            }
                        }),
                        length: data.length,
                        label: PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE_LABEL,
                    }

                default:
                    return {
                        ...programmingType,
                        videos: data,
                        length: data.length,
                    }
            }
        },

        loadAllTaskDetails(season) {
            if (!season.allTaskDetails) {
                season.allTaskDetails = AdminTasksListDataLoader.loadSeasonTasks(season).then(
                    (tasks) => {
                        const allTaskDetails = fp.flow(
                            fp.map((task) => task.task_details),
                            fp.uniq
                        )(tasks)

                        season.allTaskDetails = allTaskDetails
                        return season.allTaskDetails
                    }
                )
            }

            return season.allTaskDetails
        },

        loadSeasonVideos(season) {
            if (!season.videos) {
                const { seriesTitle: series, seasonNumber } = season

                season.videos = $http
                    .get(`/api/admin/tasks/series/videos`, {
                        params: { series, season: seasonNumber },
                        ...NO_LOAD_OVERLAY,
                    })
                    .then((res) => res.data)
                    .then((videos) => {
                        // replace promise with the data
                        season.videos = videos
                        return season.videos
                    })
            }

            return season.videos
        },

        loadSeasonTasks(season) {
            return $q
                .resolve(season.videos)
                .then((videos) => {
                    const tasks = _.flatMap(videos, (video) =>
                        AdminTasksListDataLoader.loadVideoTasks(video)
                    )
                    return $q.all(tasks)
                })
                .then((tasks) => _.flatten(tasks))
        },

        loadVideoTasks(video) {
            if (!video.tasks) {
                const map3id = encodeURIComponent(video.map3id)

                video.tasks = $http
                    .get(`/api/admin/tasks/video/${map3id}`, NO_LOAD_OVERLAY)
                    .then((res) => res.data)
                    .then((tasks) => {
                        video.tasks = tasks
                        return video.tasks
                    })
            }

            return video.tasks
        },
    }

    return AdminTasksListDataLoader
}
