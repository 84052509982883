export enum WorkerAssignmentClass {
    tagScenes = 'tagScenes',
    questionVideo = 'questionVideo',
    cutntag = 'cutntag',
    authorityTagging = 'authorityTagging',
    shapeTagging = 'shapeTagging',
    imageTagging = 'imageTagging',
    multipleImageTagging = 'multipleImageTagging',
    marker = 'marker',
}

export interface IWorkerAttribute {
    uri: string
    label: string
    value: null | string | number
    mandatory: boolean
    details: IWorkerDetail[]
}

export interface IWorkerDetail {
    uri: string
    label: string
    value: null | string | number
    multiple: boolean
    mandatory: boolean
}

export interface IWorkerAssignment {
    assignmentID: string
    class: WorkerAssignmentClass
    [key: string]: any
}

export interface IValidation {
    errors: any[]
    warnings: any[]
}
