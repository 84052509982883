import _ from 'lodash'
import angular from 'angular'
import { AnnotationValidationInstance } from '../services/AnnotationValidation.factory'
import { DataEntryNetworkGuardInstance } from '../services/annotation/DataEntryNetworkGuard.factory'
import { GlobalShortcutsInstance } from '../global-shortcuts/GlobalShortcuts.factory'
import { buildGetAnswerValue } from '../services/annotation/buildGetAnswerValue'
import { GroupedSegment, SegmentGrouper } from '../services/scenes/SegmentGrouper'
import { getColorFromIndex } from '../services/BoundryBoxDrawer.factory'

import { slugify } from 'services/slugify'
import Storage from '../services/Storage'

import { trackButtonClick, setSnowplowContext } from 'util/snowplow'
import { filterAnnotations, filterMarkers, filterSegments } from 'util/answersFilterBySearch'
import { WORKER_ROUTES } from 'constants.es6'
import { DOMUtilityInstance } from 'services/DOMUtility.factory'

type WorkerAnnotation = BaseAnnotation & {
    $$editing?: WorkerAnnotation
}

type Task = {
    annotations: WorkerAnnotation[]
    assignmentID: string
    hitID: string
    bookmarks: any[]
    options: {
        short_description: string
        order: any[]
        require_viewing: boolean
        use_timestamp: boolean
        use_bounding_box: boolean
    }
    questions: Question[]
    require_viewing: boolean
    scenes: {
        scenes: Segment[] | null
        subScenes: Segment[] | null
    }
    short_description: string
    show_instructions: boolean
}

export default /* @ngInject */ function AnnotateTaskCtrl(
    this: unknown,

    ANNOTATION_TIMESTAMP_DISABLED_VALUE: boolean,

    $scope: ng.IScope,
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    $uibModal: ng.ui.bootstrap.IModalService,

    MapDialog: any,
    GlobalShortcuts: GlobalShortcutsInstance,
    TaskService: any,
    TaskListService: any,
    Notification: any,
    MarkerTransformation: any,
    CommentInterface: any,
    AnnotationValidation: AnnotationValidationInstance,
    AnnotationSeeker: any,
    DataEntryNetworkGuard: DataEntryNetworkGuardInstance,
    EditAnnotation: any,
    DOMUtility: DOMUtilityInstance,
    task: Task,
    assignmentID: string,
    videoData: any,
    commentThreads: any
) {
    interface AnnotateTaskCtrlInstance {
        // funcs
        resetNewAnnotation: typeof resetNewAnnotation
        saveAnnotationAndClear: typeof saveAnnotationAndClear
        clearForm: typeof clearForm
        editAnnotation: typeof editAnnotation
        cancelEditing: typeof cancelEditing
        cancelNewAnnotation: typeof cancelNewAnnotation
        duplicateAnnotation: typeof duplicateAnnotation
        deleteAnnotation: typeof deleteAnnotation
        submitAllAndEnd: typeof submitAllAndEnd
        confirmAnnotationDelete: typeof confirmAnnotationDelete
        confirmReturnTask: typeof confirmReturnTask
        saveEditedAnnotation: typeof saveEditedAnnotation
        getColorFromIndex: (index?: number) => string
        buildGetAnswerValue: typeof buildGetAnswerValue

        filterAnnotationsBySearch: typeof filterAnnotationsBySearch
        filterSegmentsBySearch: typeof filterSegmentsBySearch
        filterMarkersBySearch: typeof filterMarkersBySearch
        hideEmptyGroups: boolean
        searchString: string

        // vars
        videoApi: any
        commentThreads: typeof commentThreads
        sceneData: (typeof task)['scenes']
        newAnnotation: WorkerAnnotation | null

        shortcutsGroup: {
            title: string
            shortcuts: any[]
        }
        hideVideo: boolean
        questions: (typeof task)['questions']
        options: (typeof task)['options']
        video: typeof videoData
        annotations: WorkerAnnotation[]
        bookmarks: (typeof task)['bookmarks']

        generalForm: undefined | ng.IFormController
        markersList: any[]
        allMarkers: any[]

        segmentGrouper: InstanceType<typeof SegmentGrouper>

        isSaving: boolean
        sideBySide?: boolean
        triggerSideBySide: () => void
    }

    const vm = this as AnnotateTaskCtrlInstance

    vm.resetNewAnnotation = resetNewAnnotation
    vm.saveAnnotationAndClear = DataEntryNetworkGuard.create(saveAnnotationAndClear)

    vm.commentThreads = commentThreads
    vm.clearForm = clearForm
    vm.editAnnotation = editAnnotation
    vm.cancelEditing = cancelEditing
    vm.cancelNewAnnotation = cancelNewAnnotation
    vm.duplicateAnnotation = duplicateAnnotation
    vm.deleteAnnotation = deleteAnnotation
    vm.submitAllAndEnd = submitAllAndEnd
    vm.confirmAnnotationDelete = confirmAnnotationDelete
    vm.confirmReturnTask = confirmReturnTask
    vm.saveEditedAnnotation = saveEditedAnnotation
    vm.buildGetAnswerValue = buildGetAnswerValue
    vm.triggerSideBySide = triggerSideBySide
    vm.getColorFromIndex = getColorFromIndex

    vm.filterAnnotationsBySearch = filterAnnotationsBySearch
    vm.filterSegmentsBySearch = filterSegmentsBySearch
    vm.filterMarkersBySearch = filterMarkersBySearch
    vm.hideEmptyGroups = false
    vm.searchString = ''

    vm.segmentGrouper = new SegmentGrouper(task.scenes, task.annotations)

    vm.sceneData = task.scenes

    vm.newAnnotation = null

    vm.shortcutsGroup = {
        title: 'Tagging Shortcuts',
        shortcuts: [
            {
                description: 'Next Annotation',
                keyCombo: ['ctrl+shift+]'],
                shortcut: 'control + shift+]',
                callback: () => AnnotationSeeker.seekOffset(vm.videoApi, task.annotations, 1),
            },
            {
                description: 'Previous Annotation',
                keyCombo: ['ctrl+shift+['],
                shortcut: 'control + shift+[',
                callback: () => AnnotationSeeker.seekOffset(vm.videoApi, task.annotations, -1),
            },
            {
                description: 'Save Annotation',
                global: true,
                keyCombo: ['ctrl+s', 'meta+s'],
                shortcut: 'control+S',
                callback: shortcutOnSaveAndClear,
            },
            {
                description: 'Clear Selected Values',
                keyCombo: ['esc'],
                shortcut: 'Esc',
                callback: cancelNewAnnotation,
            },
            {
                description: 'Read comment again',
                keyCombo: ['alt+r'],
                shortcut: 'Alt + R',
                callback: () => CommentInterface.tryToOpenCommentFromUrlParams(),
            },
        ],
    }

    activate()

    //////////////////////////

    function activate() {
        if (task.show_instructions) {
            $uibModal.open({
                size: 'lg',
                templateUrl: 'js/worker/task.annotate.instructions.tpl.html',
            })
        }

        // start in side-by-side mode when we have less than 2 questions
        vm.sideBySide =
            Storage.getOrUseDefault('map3_side_by_side_preference', true) &&
            task.questions.length <= 2

        $setupShortcuts()
        // if (task.options.use_bounding_box) {
        //     $setupBoundingBoxes()
        // }

        CommentInterface.init({
            hitID: task.hitID,
            threads: vm.commentThreads,
            assignmentID,
        })
        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)
        $scope.$on('$destroy', CommentInterface.destroy)

        vm.questions = task.questions
        vm.options = task.options
        vm.video = videoData

        vm.annotations = _.isArray(task.annotations) ? task.annotations : []
        vm.bookmarks = task.bookmarks

        $scope.$watchCollection('vm.annotations', (annotations: (typeof vm)['annotations']) => {
            vm.segmentGrouper.updateAnnotations(annotations)
            allAnnotationsAreRendered = false
        })
        $scope.$watchCollection('vm.bookmarks', updateMarkersList)
        $scope.$watchCollection('vm.commentThreads', updateMarkersList)
        $scope.$watch('vm.searchString', filterMarkersBySearch)

        let allAnnotationsAreRendered = false
        $scope.$on('onFinishRender', () => {
            if (_.every(vm.annotations, '$$hashKey') && !allAnnotationsAreRendered) {
                allAnnotationsAreRendered = true
                updateMarkersList()
            }
        })

        vm.resetNewAnnotation()

        setSnowplowContext('task', task)

        $scope.$on('$destroy', () => {
            setSnowplowContext('task', null)
            vm.segmentGrouper.destroy()
        })
    }

    function triggerSideBySide() {
        vm.sideBySide = !vm.sideBySide
        Storage.setItem('map3_side_by_side_preference', vm.sideBySide)
        $scope.$broadcast('resize')
    }

    function $setupShortcuts() {
        // Activate global shortcuts
        const unbindShortcuts = GlobalShortcuts.bind(vm.shortcutsGroup)
        // when exiting from this page, remove global shortcuts
        $scope.$on('$destroy', unbindShortcuts)
    }

    function executeSave(): ng.IPromise<any> {
        if (vm.isSaving) {
            return $q.resolve(false)
        }

        let newAnnotation = vm.newAnnotation
        if (!AnnotationValidation.isValid<WorkerAnnotation>(newAnnotation, vm.questions)) {
            return $q.resolve(false)
        }

        newAnnotation.timestamp = $getTimestampForAnnotation()

        newAnnotation = angular.copy(newAnnotation)
        const length = vm.annotations.push(newAnnotation)
        vm.isSaving = true

        const promise = TaskService.setAnnotations(assignmentID, vm.annotations)
        promise
            .then(() => {
                const identifier = _.get(vm.annotations[length - 1], '$$hashKey') || ''
                const scrollTargetClass = `.annotation-${slugify(identifier)}`
                $timeout(() => {
                    DOMUtility.scrollTo(scrollTargetClass, {
                        highlight: true,
                    })
                }, 10)
            })
            .finally(() => {
                vm.isSaving = false
            })

        return promise
    }

    function saveAnnotationAndClear(): ng.IPromise<any> {
        return executeSave().then(() => {
            vm.resetNewAnnotation()
            vm.clearForm()
        })
    }

    function shortcutOnSaveAndClear(event: KeyboardEvent) {
        if (vm.isSaving) return

        DataEntryNetworkGuard.execute(() => vm.saveAnnotationAndClear())

        event.preventDefault()
        return false
    }

    function addBookmark() {
        const timestamp = vm.videoApi.getCurrentFrameTime()
        vm.bookmarks.push(timestamp)

        TaskService.setBookmarks(assignmentID, vm.bookmarks)
    }

    function deleteBookmark(_: any, marker: any) {
        const index = vm.bookmarks.indexOf(marker.time)
        vm.bookmarks.splice(index, 1)

        TaskService.setBookmarks(assignmentID, vm.bookmarks)
    }

    function clearForm() {
        if (vm.generalForm) {
            vm.generalForm.$setPristine()
        }
    }

    function resetNewAnnotation() {
        vm.newAnnotation = null
    }

    function cancelNewAnnotation() {
        clearForm()
        resetNewAnnotation()
    }

    function cancelEditing(annotation: WorkerAnnotation) {
        EditAnnotation.cancelEdit(vm.annotations, annotation)
    }

    function editAnnotation(annotation: WorkerAnnotation) {
        if (annotation.timestamp) {
            vm.videoApi.seek(annotation.timestamp)
        }

        EditAnnotation.createEditCopy(annotation)
    }

    function saveEditedAnnotation(annotation: WorkerAnnotation) {
        if (!AnnotationValidation.isValid(annotation.$$editing, vm.questions)) {
            return $q.reject(`Not a valid QA annotation: ${annotation.$$editing}`)
        }

        EditAnnotation.saveEdit(vm.annotations, annotation)

        return TaskService.setAnnotations(assignmentID, vm.annotations)
    }

    function duplicateAnnotation(annotation: WorkerAnnotation) {
        vm.newAnnotation = EditAnnotation.duplicatedAnnotation(annotation)
        vm.generalForm?.$setDirty()
    }

    function confirmAnnotationDelete(annotation: WorkerAnnotation) {
        const hasUnknownValue = AnnotationValidation.checkForIrrelevant(annotation)
        let content

        if (hasUnknownValue && task.show_instructions) {
            content = `
                <div class="col-12 text-center">
                    <img src="images/worker-instruction-image-01.png" alt="">
                    <p>
                        <span class="text-primary h4 mr-2">&bull;</span>
                        Prioritize the correction of ‘unknown’ tags rather than adding new ones
                    </p>
                    <p class="modal-body-text mt-3">Are you sure you want to delete the annotation?</p>
                </div>
            `
        } else {
            content = `<p class="modal-body-text">Are you sure you want to delete the annotation?</p>`
        }

        const deleteConfig = MapDialog.confirm()
            .title('Delete annotation')
            .htmlContent(content)
            .ok('Yes')
            .cancel('No')

        MapDialog.show(deleteConfig).then(function () {
            vm.deleteAnnotation(annotation)
        })
    }

    function submitAllAndEnd() {
        if (checkForOpenedEditAnnotations()) {
            Notification.error(`You haven't finished editing an annotation.`)
            return false
        }

        const submitConfig = MapDialog.confirm()
            .title('Submit task')
            .htmlContent(
                `
                <strong>Are you sure you want to proceed?</strong>
                <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
                ${
                    !hasTags()
                        ? `
                    <div class="alert alert-warning" role="alert">
                        You have no annotations data. Are you sure you want to proceed?
                    </div>
                    `
                        : ''
                }
            `
            )
            .ok('Yes')
            .cancel('No')

        MapDialog.show(submitConfig)
            .then(() => {
                // secondary confirmation for empty task submission
                if (hasTags()) {
                    return true
                }

                return MapDialog.confirm()
                    .title('Are you sure you want to submit an empty task?')
                    .htmlContent(
                        `
                        <div class="alert alert-danger">
                            <strong>You are about to submit an empty task</strong>, with no annotation data.
                            <br />
                            <br />
                            Are you really sure you want to submit an empty task?
                        </div>
                    `
                    )
                    .ok('Yes')
                    .cancel('No')
                    .show()
            })
            .then(function () {
                trackButtonClick({
                    label: 'Submit Task',
                    componentName: 'Annotate Task Submit',
                    value: [
                        {
                            metadata: {
                                hitId: task.hitID,
                                hasTags: hasTags(),
                            },
                        },
                    ],
                })

                submitAnnotations()
            })
    }

    function deleteAnnotation(annotation: WorkerAnnotation) {
        const index = $getAnnotationIndex(annotation)
        vm.annotations.splice(index, 1)

        Notification.forPromise(TaskService.setAnnotations(assignmentID, vm.annotations))
    }

    function submitAnnotations() {
        Notification.forPromise(
            TaskService.submitAnnotations(assignmentID, vm.annotations).then(function () {
                $state.go('worker.list')
            })
        )
    }

    function confirmReturnTask() {
        const modalConfig = MapDialog.confirm()
            .title('Abandon Task')
            .textContent(
                'If you abandon this task, all saved annotations will be erased and the task will return to the Available Tasks list.'
            )
            .ok('Abandon')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(function () {
            trackButtonClick({
                label: 'Abandon Task',
                value: [
                    {
                        metadata: {
                            hitId: task.hitID,
                        },
                    },
                ],
            })

            // returnTask(task)
            TaskListService.returnTask(task).then(() => {
                window.location.href = $state.href(WORKER_ROUTES.WORKER_LIST.STATE_NAME)
                window.location.reload()
            })
        })
    }

    function updateMarkersList() {
        vm.allMarkers = _.concat(
            MarkerTransformation.bookmarks(vm.bookmarks),
            MarkerTransformation.threads(vm.commentThreads, CommentInterface.markerClick),
            MarkerTransformation.tags(vm.annotations)
        )

        filterMarkersBySearch()
    }

    function filterMarkersBySearch() {
        vm.markersList = filterMarkers(vm.allMarkers, vm.searchString)
    }

    function filterAnnotationsBySearch(annotation: BaseAnnotation) {
        return filterAnnotations(annotation, vm.searchString)
    }

    function filterSegmentsBySearch(segment: GroupedSegment) {
        return filterSegments(segment, vm.searchString)
    }

    function hasTags() {
        return !!vm.annotations.length
    }

    function checkForOpenedEditAnnotations() {
        return _.some(vm.annotations, '$$editing')
    }

    function $getAnnotationIndex(annotation: WorkerAnnotation) {
        return vm.annotations.indexOf(annotation)
    }

    function $getTimestampForAnnotation() {
        return vm.options.use_timestamp
            ? vm.videoApi.getCurrentFrameTime()
            : ANNOTATION_TIMESTAMP_DISABLED_VALUE
    }
}

AnnotateTaskCtrl.resolve = {
    assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.assignmentID
    },

    task: /* @ngInject */ function (TaskService: any, assignmentID: string): Promise<Task> {
        // for when you need LIGHTSPEED
        // return import('../../sample/answerQuestion.json').then((m) => m.default)

        return TaskService.getTask(assignmentID)
    },

    videoData: /* @ngInject */ function (VideoService: any, task: Task) {
        return VideoService.get(task.hitID)
    },

    commentThreads: /* @ngInject */ function (CommentService: any, task: Task) {
        // for when you need LIGHTSPEED
        // return []

        return CommentService.getThreads(task.hitID) || [] // eslint-disable-line no-mixed-operators
    },
}
