import angular from 'angular'
import _ from 'lodash'
import { ActionCreators as ReduxUndoActionCreators } from 'redux-undo'

import {
    NewRelicErrorCode,
    QA_ROUTES,
    UserType,
    VGPlayerTimeFormat,
    WORKER_ROUTES,
} from 'constants.es6'
import { TContentMarker, TContentMarkerBackend, IContentMarkerVideoPlayerMarker } from './types'
import { ContentMarkersBackendInterfaceServiceInstance } from './ContentMarkersBackendInterfaceService.factory'
import {
    ContentMarkersActionCreatorsInstance,
    ContentMarkersActionsInstance,
    ContentMarkersStateActionsInstance,
} from './ContentMarkersReducer'
import {
    buildContentMarkersModelCollection,
    buildContentMarkersForSave,
    isPair,
    isMarkersDataValid,
} from './utils'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { getFrameFormatter } from 'services/utils'
import { TIME_FORMAT_DROPDOWN_CHANGE_EVENT } from 'directives/constants'
import { newRelic } from 'util/newRelic'
import { IVideoData } from 'video/types'
import { setSnowplowContext, trackButtonClick } from 'util/snowplow'

const INVALID_MARKERS_DATA_ERROR_MESSAGE = 'Invalid markers data.'

const DF_LIKE_VIDEOS = [29.97, 59.94]

const commonResolve = {
    videoData: /* @ngInject */ (VideoService: any, hitID: string) => VideoService.get(hitID),
}

interface IContentMarkesBackendInterface {
    setContentMarkers(hitID: string, contentMarkers: TContentMarkerBackend[]): ng.IPromise<any>
    submitTask(hitID: string, contentMarkers: TContentMarkerBackend[]): ng.IPromise<any>
    navigateToTaskList(): void
    returnToWorkers(hitID: string, workers: any[]): ng.IPromise<any>
}

export default class ContentMarkersCtrl {
    static resolveQA = {
        ...commonResolve,
        assignmentID: () => null,
        hitID: /* @ngInject */ ($stateParams: ng.ui.IStateParamsService) => $stateParams.id,
        task: /* @ngInject */ (
            ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
            hitID: string
        ) => ContentMarkersBackendInterfaceService.getQAData(hitID),
        userType: () => UserType.QA,
        BackendInterface: /* @ngInject */ (
            ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
            $state: ng.ui.IStateService
        ) => {
            const QABackendInterface: IContentMarkesBackendInterface = {
                setContentMarkers(hitID: string, contentMarkers: TContentMarkerBackend[]) {
                    return ContentMarkersBackendInterfaceService.setQAContentMarkers(
                        hitID,
                        contentMarkers
                    )
                },
                submitTask(hitID: string, contentMarkers: TContentMarkerBackend[]) {
                    return ContentMarkersBackendInterfaceService.submitQAContentMarkers(
                        hitID,
                        contentMarkers
                    )
                },
                navigateToTaskList() {
                    $state.go(QA_ROUTES.QA_LIST.STATE_NAME)
                },
                returnToWorkers(hitID: string) {
                    return ContentMarkersBackendInterfaceService.returnToWorkers(hitID)
                },
            }

            return QABackendInterface
        },
    }

    static resolveWorker = {
        ...commonResolve,
        assignmentID: /* @ngInject */ ($stateParams: ng.ui.IStateParamsService) =>
            $stateParams.assignmentID,
        hitID: /* @ngInject */ (task: any) => task.hitID,
        task: /* @ngInject */ (
            ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
            assignmentID: string
        ) => ContentMarkersBackendInterfaceService.getWorkerData(assignmentID),
        userType: () => UserType.Worker,
        BackendInterface: /* @ngInject */ (
            ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
            $state: ng.ui.IStateService
        ) => {
            const WorkerBackendInterface: IContentMarkesBackendInterface = {
                setContentMarkers(assignmentID: string, contentMarkers: TContentMarker[]) {
                    return ContentMarkersBackendInterfaceService.setWorkerContentMarkers(
                        assignmentID,
                        contentMarkers
                    )
                },
                submitTask(assignmentID, highlights) {
                    return ContentMarkersBackendInterfaceService.submitWorkerContentMarkers(
                        assignmentID,
                        highlights
                    )
                },
                navigateToTaskList() {
                    $state.go(WORKER_ROUTES.WORKER_LIST.STATE_NAME)
                },
                returnToWorkers: angular.noop as any,
            }

            return WorkerBackendInterface
        },
    }

    $scope: ng.IScope
    $ngRedux: any
    MapDialog: any

    globalShortcuts: any
    markerTransformation: any
    sharedVideoAPI: any
    contentMarkerBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance
    contentMarkerStateActions: ContentMarkersStateActionsInstance
    contentMarkerActions: ContentMarkersActionsInstance
    contentMarkerActionCreators: ContentMarkersActionCreatorsInstance
    backendInterface: IContentMarkesBackendInterface
    notification: any

    userType: UserType
    task: any
    video: IVideoData
    workers: any[]
    hitID: string
    assignmentID: string

    selectedContentMarkerId: any
    selectedContentMarker: any
    isPair: typeof isPair

    markersList: any[] = []

    // eslint-disable-next-line
    videoApi!: VideoAPIInstance
    contentMarkersFilmstripMode = 'single'
    contentMarkers: TContentMarker[] = []
    lastSavedBackendContentMarkers: TContentMarkerBackend[] = []
    contentMarkersRenderMode = 'condensed'
    timeFormat: VGPlayerTimeFormat = VGPlayerTimeFormat.Tape
    hasDropFrames = false

    executeSave: (contentMarkers: TContentMarkerBackend[]) => ng.IPromise<any>

    /* @ngInject */
    constructor(
        $scope: ng.IScope,
        $ngRedux: any,
        MapDialog: any,

        ContentMarkersActions: ContentMarkersActionsInstance,
        ContentMarkerStateActions: ContentMarkersStateActionsInstance,
        ContentMarkersActionCreators: ContentMarkersActionCreatorsInstance,

        Notification: any,
        GlobalShortcuts: any,
        MarkerTransformation: any,
        DataEntryNetworkGuard: any,
        SharedVideoAPI: any,
        ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
        BackendInterface: IContentMarkesBackendInterface,

        userType: UserType,
        task: any,
        hitID: string,
        assignmentID: string,
        videoData: IVideoData
    ) {
        this.$scope = $scope
        this.$ngRedux = $ngRedux
        this.MapDialog = MapDialog
        this.globalShortcuts = GlobalShortcuts
        this.markerTransformation = MarkerTransformation
        this.sharedVideoAPI = SharedVideoAPI
        this.contentMarkerBackendInterfaceService = ContentMarkersBackendInterfaceService
        this.contentMarkerStateActions = ContentMarkerStateActions
        this.contentMarkerActions = ContentMarkersActions
        this.contentMarkerActionCreators = ContentMarkersActionCreators
        this.backendInterface = BackendInterface
        this.notification = Notification

        this.userType = userType
        this.task = task
        this.video = videoData
        this.workers = userType === UserType.QA ? task.workers : []
        this.hitID = hitID
        this.assignmentID = assignmentID

        this.selectedContentMarkerId = null
        this.selectedContentMarker = null

        this.hasDropFrames =
            !!this.video.dropFrameTimecode || DF_LIKE_VIDEOS.includes(this.video.frameRate)
        this.timeFormat = this.hasDropFrames ? VGPlayerTimeFormat.TapeDF : VGPlayerTimeFormat.Tape

        this.isPair = isPair

        this.executeSave = DataEntryNetworkGuard.create(
            (backendContentMarkers: TContentMarkerBackend[]) => {
                const promise = this.backendInterface.setContentMarkers(
                    assignmentID || hitID,
                    backendContentMarkers
                )

                this.notification.forPromise(promise, 'Successfully saved markers')
                return promise
            }
        )

        setSnowplowContext('task', task)

        $scope.$on('$destroy', function destroyContentMarkersCtrl() {
            setSnowplowContext('task', null)
        })
    }

    saveIfNecessary() {
        const backendContentMarkers = buildContentMarkersForSave(this.contentMarkers)

        if (!angular.equals(this.lastSavedBackendContentMarkers, backendContentMarkers)) {
            this.lastSavedBackendContentMarkers = angular.copy(backendContentMarkers)
            this.executeSave(backendContentMarkers)
        }
    }

    updateContentMarkersState() {
        const contentMarkersState = this.contentMarkers.map((contentMarker) => {
            const contentMarkersState = {
                id: contentMarker.id,
            }

            return contentMarkersState
        })

        this.contentMarkerStateActions.update({ contentMarkersState })
    }

    updateMarkersList() {
        const frameFormatter = getFrameFormatter(this.timeFormat, this.videoApi)
        this.markersList = _.concat(
            this.markerTransformation.contentMarkers(
                this.contentMarkers.map((marker) => {
                    return {
                        id: marker.id,
                        timestamp: marker.timestamp,
                        frameNumber:
                            marker.frameNumber ||
                            this.videoApi.convertLaxSecondToFrame(marker.timestamp),
                        formattedTime: frameFormatter(marker.frameNumber),
                        type: marker.type,
                        rating: _.get(marker, 'rating', false),
                    } as IContentMarkerVideoPlayerMarker
                }),
                (_e: any, data: IContentMarkerVideoPlayerMarker) => {
                    this.contentMarkerActions.selectById({ id: data.id })
                }
            )
        )
    }

    confirmSaveAndSubmit() {
        const backendContentMarkers = buildContentMarkersForSave(this.contentMarkers)
        const confirmDialog = this.MapDialog.confirm()
            .title(`Submit Markers Data`)
            .htmlContent(
                `
                    <strong>Are you sure you want to proceed?</strong>
                    <p>
                        When you submit your changes will be stored and you won't be able to edit them anymore.
                    </p>
                    ${
                        _.isEmpty(backendContentMarkers)
                            ? `
                        <div class="alert alert-warning" role="alert">
                            You have no markers data. Are you sure you want to proceed?
                        </div>
                        `
                            : ''
                    }
                `
            )
            .ok('Yes')
            .cancel('No')
        this.MapDialog.show(confirmDialog).then(() => {
            trackButtonClick({
                label: 'Submit Markers Data',
                value: [
                    {
                        metadata: {
                            hitId: this.assignmentID || this.hitID,
                        },
                    },
                ],
            })

            this.backendInterface
                .submitTask(this.assignmentID || this.hitID, backendContentMarkers)
                .then(() => this.backendInterface.navigateToTaskList())
        })
    }

    isWorkerTask() {
        return this.userType === UserType.Worker
    }

    returnToWorkers() {
        const confirmDialog = this.MapDialog.confirm()
            .title(`Return to workers`)
            .htmlContent(
                `
                    <strong>Are you sure you want to proceed?</strong>
                `
            )
            .ok('Yes')
            .cancel('No')

        this.MapDialog.show(confirmDialog).then(() =>
            this.backendInterface
                .returnToWorkers(this.hitID, this.workers)
                .then(() => this.backendInterface.navigateToTaskList())
        )
    }

    $setupContentMarkersData() {
        const contentMarkers = buildContentMarkersModelCollection(this.task.markers, this.videoApi)

        if (!isMarkersDataValid(contentMarkers)) {
            newRelic.noticeError(
                newRelic.getNewRelicErrorMessage(
                    NewRelicErrorCode.InvalidContentMarkerPairs,
                    INVALID_MARKERS_DATA_ERROR_MESSAGE
                ),
                contentMarkers
            )
        }

        this.$ngRedux.dispatch(ReduxUndoActionCreators.clearHistory())
        this.contentMarkerActions.init({ contentMarkers, videoApi: this.videoApi })
        this.$ngRedux.dispatch(ReduxUndoActionCreators.clearHistory())

        // let lastContentMarker: any
        const unsubscribeSubscribe = this.$ngRedux.subscribe(() => {
            // ? Unused. Consider deletion.
            // const { contentMarkersData } = self.$ngRedux.getState()
            // const currentContentMarker = contentMarkersData.undoableContentMarkers.present.contentMarkers
            // lastContentMarker = currentContentMarker
        })

        const unsubscribeConnect = this.$ngRedux.connect(mapStateToThis)(this)

        function mapStateToThis({
            contentMarkersData: {
                undoableContentMarkers: {
                    present: { contentMarkers },
                },
            },
        }: any) {
            const selected = _.filter(contentMarkers, { $$isSelected: true })

            return {
                contentMarkers,
                selectedContentMarkerId: selected.length === 1 ? selected[0].id : null,
            }
        }

        this.$scope.$on('$destroy', () => {
            unsubscribeSubscribe()
            unsubscribeConnect()
        })

        this.$scope.$on(TIME_FORMAT_DROPDOWN_CHANGE_EVENT, (_$event, timeFormat) => {
            const isDF = timeFormat === VGPlayerTimeFormat.TapeDF
            this.videoApi.setDropFramesMode(isDF)
            this.timeFormat = timeFormat
        })

        this.$scope.$watchGroup(
            ['vm.selectedContentMarkerId', 'vm.contentMarkers'],
            ([id, contentMarkers]) => {
                const contentMarker = _.find(contentMarkers, { id })

                // set editing highlight only if we have a valid non-empty non-provisional one
                if (!contentMarker || contentMarker.$$isProvisional) {
                    this.selectedContentMarker = null

                    return
                }

                this.selectedContentMarker = contentMarker
            }
        )
    }

    $setupWatchFunctions() {
        this.lastSavedBackendContentMarkers = angular.copy(
            buildContentMarkersForSave(this.contentMarkers)
        )

        this.$scope.$watch(
            'vm.contentMarkers',
            () => {
                this.saveIfNecessary()
                this.updateContentMarkersState()
                this.updateMarkersList()
            },
            /* deep */ true
        )

        this.$scope.$watch(
            'vm.timeFormat',
            () => {
                this.updateMarkersList()
            },
            /* deep */ true
        )
    }

    $setupFilmstrip() {
        this.$scope.$on('change-film-strip-mode', (_, zoom) => {
            if (zoom === 100) {
                this.contentMarkersFilmstripMode = 'full'
            } else {
                this.contentMarkersFilmstripMode = 'single'
            }
        })
        this.videoApi.getFilmStripDrawer(() => {
            this.contentMarkersFilmstripMode = 'single'
        })
    }

    $onInit() {
        this.sharedVideoAPI.onLoad((videoApi: VideoAPIInstance) => {
            this.videoApi = videoApi
            this.$setupContentMarkersData()
            this.$setupWatchFunctions()
            this.$setupFilmstrip()
        })
    }
}
