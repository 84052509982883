import _ from 'lodash'

import { ASSIGNMENT_STATUSES } from '../constants'
import { WorkerAssignmentClass } from './types'
import { TaskRouterInstance } from './routes'
import { TaskServiceInstance } from './TaskService.factory'
import { trackButtonClick } from 'util/snowplow'

const ASSIGNED = 'assigned'
const ASSIGNABLE = 'assignable'
const RETURNED = 'returned'
const ON_HOLD = 'onHold'

type AssignmentStatus =
    | typeof ASSIGNED
    | typeof ASSIGNABLE
    | typeof RETURNED
    | typeof ON_HOLD
    | unknown
type Issue = {
    issue_status: string
    issue_type: string
    missing_authority_request_id: string
}

type Assignment<Status extends AssignmentStatus> = {
    hitID: string
    task_status: Status
    issue: Issue
}
type AssignedAssignment<Status extends AssignmentStatus> = Assignment<Status> & {
    assignmentID: string
    class: WorkerAssignmentClass
}
type MISSING_AUTHORITY_ERROR_DATA_TYPE = Error & {
    issue: Issue
}

export default /* @ngInject */ function TaskListCtrl(
    this: unknown,
    $q: ng.IQService,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    TaskListService: any,
    TaskService: TaskServiceInstance,
    TaskRouter: TaskRouterInstance,
    Notification: any,
    MapDialog: any,
    UserPreferences: any,
    createMissingAuthorityHandler: any,
    assignments: Assignment<unknown>[]
) {
    interface TaskListCtrlInstance {
        AUTHORITY_FOUND_STATUS: typeof ASSIGNMENT_STATUSES.AUTHORITY_FOUND_STATUS
        returnedTaskListPredicatePerfield: unknown
        assignedTaskListPredicatePerfield: unknown
        assignableTaskListPredicatePerfield: unknown
        onHoldTaskListPredicatePerfield: unknown

        returnedTaskListPredicateOrderBy: unknown
        assignedTaskListPredicateOrderBy: unknown
        assignableTaskListPredicateOrderBy: unknown
        onHoldTaskListPredicateOrderBy: unknown
        assignments: typeof assignments

        urlForAssignment: TaskRouterInstance['urlFor']
        acceptTasks: typeof acceptTasks
        getTask: (typeof TaskService)['getTask']
        workOnTask: typeof workOnTask
        retryTask: typeof retryTask
        confirmRetrunToInProgress: typeof confirmRetrunToInProgress
        confirmReturnTask: typeof confirmReturnTask

        assignedTaskList: Assignment<typeof ASSIGNED>[]
        assignableTaskList: Assignment<typeof ASSIGNABLE>[]
        returnedTaskList: Assignment<typeof RETURNED>[]
        onHoldTaskList: Assignment<typeof ON_HOLD>[]
    }
    const vm = this as TaskListCtrlInstance

    vm.assignments = assignments
    vm.AUTHORITY_FOUND_STATUS = ASSIGNMENT_STATUSES.AUTHORITY_FOUND_STATUS

    vm.returnedTaskListPredicatePerfield = UserPreferences.get(
        $state.current.name,
        'vm.returnedTaskListPredicatePerfield',
        {}
    )
    vm.assignedTaskListPredicatePerfield = UserPreferences.get(
        $state.current.name,
        'vm.assignedTaskListPredicatePerfield',
        {}
    )
    vm.assignableTaskListPredicatePerfield = UserPreferences.get(
        $state.current.name,
        'vm.assignableTaskListPredicatePerfield',
        {}
    )
    vm.onHoldTaskListPredicatePerfield = UserPreferences.get(
        $state.current.name,
        'vm.onHoldTaskListPredicatePerfield',
        {}
    )

    vm.returnedTaskListPredicateOrderBy = UserPreferences.get(
        $state.current.name,
        'vm.returnedTaskListPredicateOrderBy',
        []
    )
    vm.assignedTaskListPredicateOrderBy = UserPreferences.get(
        $state.current.name,
        'vm.assignedTaskListPredicateOrderBy',
        []
    )
    vm.assignableTaskListPredicateOrderBy = UserPreferences.get(
        $state.current.name,
        'vm.assignableTaskListPredicateOrderBy',
        []
    )
    vm.onHoldTaskListPredicateOrderBy = UserPreferences.get(
        $state.current.name,
        'vm.onHoldTaskListPredicateOrderBy',
        []
    )

    vm.urlForAssignment = TaskRouter.urlFor
    vm.getTask = TaskService.getTask

    vm.acceptTasks = acceptTasks
    vm.confirmReturnTask = confirmReturnTask
    vm.workOnTask = workOnTask
    vm.confirmRetrunToInProgress = confirmRetrunToInProgress
    vm.retryTask = retryTask
    activate()

    ////////////////////////

    function activate() {
        $scope.$watchCollection('vm.assignments', function () {
            vm.assignedTaskList = _.filter(vm.assignments, {
                task_status: ASSIGNED,
            }) as Assignment<typeof ASSIGNED>[]

            vm.assignableTaskList = _.filter(vm.assignments, {
                task_status: ASSIGNABLE,
            }) as Assignment<typeof ASSIGNABLE>[]

            vm.returnedTaskList = _.filter(vm.assignments, {
                task_status: RETURNED,
            }) as Assignment<typeof RETURNED>[]

            vm.onHoldTaskList = _.filter(vm.assignments, {
                task_status: ON_HOLD,
            }) as Assignment<typeof ON_HOLD>[]
        })
    }

    function retryTask(task: AssignedAssignment<typeof ON_HOLD>) {
        TaskService.getTask(task.assignmentID)
            .then(() => {
                return TaskRouter.go(task)
            })
            .catch(createMissingAuthorityHandler(task))
    }
    function confirmRetrunToInProgress(task: AssignedAssignment<typeof ASSIGNED>) {
        const modalConfig = MapDialog.confirm()
            .title('Return task In Progress')
            .textContent(
                'You are about to return a task with missing terms to your ‘in progress’ tab. This will allow you to continue working and MAP3 will notify you when new terms are available.'
            )
            .ok('Return in progress')
            .okClass('btn-primary')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(function () {
            returnToInProgress(task)
        })

        ////////////////////////////////////////////////

        function returnToInProgress(task: AssignedAssignment<typeof ASSIGNED>) {
            const promise = TaskListService.returnTaskToInProgress(task).then(() => {
                const index = _.findIndex(vm.assignments, { hitID: task.hitID })

                if (index !== -1) {
                    const updatedTask = { ...task, task_status: 'assigned' }
                    vm.assignments.splice(index, 1, updatedTask)
                }
            })

            Notification.forPromise(promise)
        }
    }

    function moveToOnHold(task: AssignedAssignment<typeof ASSIGNED>) {
        const index = _.findIndex(vm.assignments, { hitID: task.hitID })

        if (index !== -1) {
            const updatedTask = { ...task, task_status: 'onHold' }
            vm.assignments.splice(index, 1, updatedTask)
        }
    }

    function workOnTask(task: AssignedAssignment<typeof ASSIGNED>) {
        trackButtonClick({
            label: 'Work on Task',
            value: [
                {
                    metadata: {
                        hitId: task.hitID,
                    },
                },
            ],
        })

        return TaskRouter.go(task)

        TaskService.getTask(task.assignmentID)
            .then(() => {
                return TaskRouter.go(task)
            })
            .catch((err: ng.IHttpResponse<MISSING_AUTHORITY_ERROR_DATA_TYPE>) => {
                if (err.status !== 422) {
                    return $q.reject(err)
                }
                const modalConfig = MapDialog.confirm()
                    .title('There are no answers for all the questions in this task.')
                    .htmlContent(
                        `
                    <div class="alert alert-warning" role="alert">
                        <p class="text-center mb-0 mt-3">The task will be moved to the tab <strong>"On Hold Tasks"</strong>. MAP3 will retry the load of answers and report an issue if no answers are found in the next hours.</p>
                        <p class="text-center mb-3"><strong>If the completion of the task is critical you can manually report the issue.</strong></p>
                        <p class="text-center">In any case you will be notified if answers for the task become available.</p>,
                    </div>
                `
                    )
                    .cancel('Close')

                task.issue = err.data.issue

                moveToOnHold(task)
                MapDialog.show(modalConfig)
            })
    }

    function acceptTasks(tasks: Assignment<typeof ASSIGNABLE>[]) {
        const promise = TaskListService.acceptTasks(tasks).then(
            (response: { failed: string[]; success: Assignment<typeof ASSIGNED>[] }) => {
                handleUnavailable(response.failed)
                replaceUpdatedWith(response.success)
            }
        )

        Notification.forPromise(promise, 'Your tasks were moved to the In Progress tab.')

        promise.then(() => {
            tasks.forEach((task) => {
                trackButtonClick({
                    label: 'Accept tasks',
                    value: [
                        {
                            metadata: {
                                hitId: task.hitID,
                            },
                        },
                    ],
                })
            })
        })

        return promise
    }

    function replaceUpdatedWith(updatedTasks: Assignment<unknown>[]) {
        _.forEach(updatedTasks, (task) => {
            const index = _.findIndex(vm.assignments, { hitID: task.hitID })
            if (index !== -1) {
                vm.assignments.splice(index, 1, task)
            } else {
                vm.assignments.push(task)
            }
        })
    }

    function handleUnavailable(unavailableHits: string[]) {
        const unavailable = _.remove(vm.assignments, (assignment) =>
            _.includes(unavailableHits, assignment.hitID)
        )

        if (unavailable.length) {
            Notification.warning(`
                <pre>
                    Some task are not available anymore:
                    ${_.map(unavailable, 'job_name').join(', ')}
                </pre>
            `)
        }
    }

    function confirmReturnTask(task: Assignment<typeof ASSIGNED>) {
        const modalConfig = MapDialog.confirm()
            .title('Abandon Task')
            .textContent(
                'If you abandon this task, all saved annotations will be erased and the task will return to the Available Tasks list.'
            )
            .ok('Abandon')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(function () {
            trackButtonClick({
                label: 'Abandon Task',
                value: [
                    {
                        metadata: {
                            hitId: task.hitID,
                        },
                    },
                ],
            })

            returnTask(task)
        })

        ////////////////////////////////////////////////

        function returnTask(task: Assignment<typeof ASSIGNED>) {
            const promise = TaskListService.returnTask(task).then(function (
                task: Assignment<typeof RETURNED>
            ) {
                replaceUpdatedWith([task])
            })

            Notification.forPromise(promise)
        }
    }
}

TaskListCtrl.resolve = {
    assignments: /* @ngInject */ function (TaskListService: any) {
        // For when you need LIGHTSPEED
        // return import('../../sample/workerTasksList.json').then((m) => m.default)
        return TaskListService.assignments()
    },
}
